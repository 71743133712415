import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../../components/layout'
import HeaderGeneric from '../../components/HeaderGeneric'

class Generic extends React.Component {
  render() {

    return (
      <Layout>
        <Helmet title="श्री गुरूगजाननलीला ग्रंथ - अध्याय २ रा" />
        <HeaderGeneric title="श्री गुरूगजाननलीला ग्रंथ" adhyaay='अध्याय २ रा' num='02'/>
        <div id="main">
          <section id="content" className="main">
            <p>
            श्री गणेशाय नम: । हे नृसिंह सरस्वती । दत्तात्रेय शक्ती । वारंवार पटवती गुरूमहती ॥ १ ॥
हे श्रीपाद श्रीवल्लभ । आपले दर्शन दुर्लभ । नामानेच सुलभ । तेणे परमार्थ सुलभ ॥ २ ॥
तुम्ही नामाने वेगळे । तुम्ही कायेने वेगळे । परी एकच सांगितले । तथ्य नामातले ॥ ३ ॥
तुम्ही आत्म्याचे बोल बोलता । जीवनातले तत्व सांगता । म्हणे तैसे तत्व आचरता । भवसागर तरता ॥ ४ ॥
नका दुखवू कुणाचा आत्मा । तेणे कोपे परमात्मा । सदा संतोषावे आत्मा । तेणे संतोषे परमात्मा ॥ ५ ॥
ऐसे आचरण करावे । दुसर्‍याच्या गाली खळी खुलणे । त्यातच आपण हसणे । तेणे जीवन सार्थक होणे ॥ ६ ॥
ज्याला वाटे आपण हसावे । त्याने दुसर्‍यास हसवावे । ऐसेच सर्वांनी वागावे । आनंदी आनंद मानावे ॥ ७ ॥
एकट्याने श्रीमंत होण्यात । अनेक उपाध्या त्यात । डावे उजवे होते त्यात । कुकर्मे घडतात ॥ ८ ॥
ऐसे धन नाही टिकत । हास्य गाली नाही खुलत । परी सर्वांच्या नयनात । अश्रूच दिसतात ॥ ९ ॥ 
ज्याने खेळ मांडला । तोच खेळ करणार गोळा । त्याचे वाचून पाचोळा । हेच सांगितले सकळा ॥ १० ॥
हे तत्व ओळखावे । हे कधी न विसरावे । हेच मनास पटवावे । हेच कृतीने आचरावे ॥ ११ ॥
तुझ्या वाचून आम्ही व्यर्थ । तुझ्या वाचून अनर्थ । तूच सर्व प्रसंगी समर्थ । ह्यातच भरला खरा अर्थ ॥ १२ ॥
सदा धर्म आचरावा । हाच मार्ग नित्य क्रमावा । तेणे आत्मा तोषवावा । परमात्मा तोषवावा ॥ १३ ॥
जेथे परमात्मा संतोषतो । तो जीव धन्य होतो । कर्मांचा भार न वाटतो । सुकर्मे आचरतो ॥ १४ ॥
हेच सांगे गजानन । पटवतो एकेक कृतीवरून । म्हणे सत्कर्मावाचून । परमात्मा न येई धाऊन ॥ १५ ॥
ऐसे गजानने केले । सर्वांचे चित्त भुलवले । जरी वायुवेगे पळाले । परी सर्वांपाशीच राहिले ॥ १६ ॥
बंकटाचे तसेच झाले । त्याचे देहभान हरपले । महाराजांत चित्त गुंतले । सर्वत्र महाराज दिसू लागले ॥ १७ ॥ 
महाराजांचा इतुका ध्यास । विसरला अन्न पाण्यास । महाराजांवीण उदास । स्वस्थता नसे चित्तास ॥ १८ ॥
पित्यास सांगावे कसे? । त्यासी धाडस होत नसे । त्यासी भिती वाटतसे । जेणे बोलणे होत नसे ॥ १९ ॥
परी वडील भवानीराम पुसे । “बंकटा, काय तुला झाले ऐसे? । चेहरा उदास दिसतसे । चित्ती हुरहुर वाटतसे ॥ २० ॥
तू तरण्या जवान पोरगा । का रे घाबरा घुबरा? । काय व्याधी तुझ्या शरीरा? । का रे सुकला तुझा चेहरा? ॥ २१ ॥
काय होते तुज सांग आधी । कोणती व्याधी उपाधी? । पुत्र न चोरून ठेवे कधी । सांग मज वृत्तांत आधी” ॥ २२ ॥
काही तरी पटवून । पित्याचे केले समाधान । वेळ नेली मारून । परी स्वस्थ न मनोमन ॥ २३ ॥
बंकटलालचे शेजारी । सद्‌गृहस्थ सदाचारी । बंकटावर जीव भारी । पुसतसे वरचेवरी ॥ २४ ॥
ते देशमुख रामाजीपंत । वयाने वृद्ध अत्यंत । तैसेच श्रेष्ठ अनुभवात । सांगितला त्यांस वृत्तांत ॥ २५ ॥
रामाजीपंतांस म्हणाला । ज्या गुरूसी मी पाहिला । तैसा आजवर कधी न दिसला । परी वायुवेगे पळाला ॥ २६ ॥
अजुन ते दिसते दृश्य । महाराजांचा सहवास । जेवला उष्ट्या पत्रावळीस । नाही भुलला लौकिकास ॥ २७ ॥
क्षणभराचा खेळ पाहिला । तोच मनावर ठसला । गुरू अदृश्य जाहला । चटका जीवाला लागला ॥ २८ ॥
महाराज म्या दिसावे । सहवास त्यांचे लाभावे । पुण्य उदयास यावे । जीवन सार्थक व्हावे ॥ २९ ॥
महाराजे चित्त भुलवले ऐसे । त्यांना विसरणार कैसे? । त्यांचे वीण चैन नसे । अन्न न रुचकर लागतसे ॥ ३० ॥
रामाजीपंत समजले । चित्त महाराजांत गुंतले । त्यांच्याही मनात विचार आले । ते ही महाराजमय झाले ॥ ३१ ॥
ते म्हणाले बंकटाला । जो तू वृत्तांत वदला । ऐसा पुरुष न पाहिला । सार्‍या योग्याच्याच लीला ॥ ३२ ॥
ऐशा क्रिया योग्यावाचून । न मिळणार पहाता शोधून । पूर्व सुकृतावाचून । न घडेल त्याचे दर्शन ॥ ३३ ॥
तू घेतलेस दर्शन । धन्य धन्य तुझे जीवन । त्यांच्या दर्शनावाचून । मलाही न पडणार चैन ॥ ३४ ॥
ऐशा स्थितीत चार दिवस । महाराज न दिसले त्यास । बंकटास त्यांचा ध्यास । विसर न पडला त्यास ॥ ३५ ॥
एके दिवशी कीर्तनकार । गोविंदबुवा टाकळीकर । ज्यांच्या कीर्तनावर । खूष होतसे शारंगधर ॥ ३६ ॥
लौकीक त्यांचा वर्‍हाडात । ऐसा जम कीर्तनात । ते आले फिरत फिरत । कीर्तन त्यांचे शेगावात ॥ ३७ ॥
शंकराच्या मंदिरी । तयारी झाली सारी । उमटल्या नर नारी । जो तो उत्सुक भारी ॥ ३८ ॥ 
बंकट कीर्तनाला चालला । पितांबर शिंपी त्यास भेटला । तो ही बरोबर निघाला । कीर्तन श्रवण करण्याला ॥ ३९ ॥
हा शिंपी पितांबर । भोळा भाबडा फार । समर्थांचा समाचार । बंकटे वदला साचार ॥ ४० ॥
दोघे कीर्तनास चालले । अवचित समर्थ दिसले । फरसावर ते बसलेले । पाहुन आश्चर्य वाटले ॥ ४१ ॥
मग कसले कीर्तन ? । दोघे गेले धावून । समर्थांपाशी जाऊन । पुसु लागले प्रश्र्न ॥ ४२ ॥
दोघे सोडून कीर्तनास । पुसु लागले महाराजांस । काय आणु खावयास । सांगाल ते ते देऊ आपणास ॥ ४३ ॥
महाराज म्हणाले बंकटास । जावे माळिणीच्या सदनास । झुणका भाकर खावयास । द्यावी तुझी ईच्छा असल्यास ॥ ४४ ॥
बंकटे झुणका भाकर आणली । ती महाराजे खाल्ली । जठराग्नीची तृप्ती झाली । स्वारी पितांबराकडे पाहू लागली ॥ ४५ ॥
तुंब्या दिला पितांबरास । महाराज म्हणाले पितांबरास । जावे सत्वर नाल्यास । सांगितले तुंब्या भरण्यास ॥ ४६ ॥
पितांबर म्हणाला महाराजांस । तुंब्या बुडेल इतुके न नाल्यास । दुसरीकडे जातो घ्यावयास । आज्ञा करावी पाणी आणण्यास ॥ ४७ ॥
ह्या नाल्यातले पाणी । खराब केले गुरांनी । तैसेच जाणार्‍या येणार्‍यांनी । तुम्हास न उपयुक्त हे पाणी ॥ ४८ ॥
गजानन बोलले तत्क्षणी । नाल्याचेच आण पाणी । आण तुंब्या नाल्यात बुडवुनी । नको उगाच ओंजळीनी ॥ ४९ ॥
नुसत्या नावाने नव्हे तो शिंपी । तर तो व्यवसायाने पण शिंपी । मोजमापा इतुके कापी । कापड वायफळ न कापी ॥ ५० ॥
कापडा इतुकेच शिवणार । त्यास दुसरे काय सुचणार? । आहे त्यात बसवणार । नीटनेटके करणार ॥ ५१ ॥
ऐसी ज्यास शिकवण । होते त्यातच उदर भरण । स्वतःवरच भरवसा ठेवुन । धंदा केला जपुन ॥ ५२ ॥
जो व्यवहारात सदा दक्ष । लौकिकातही सदा लक्ष । सदा प्रपंचात लक्ष । कैसे सच्चिदानंदात लक्ष? ॥ ५३ ॥ 
एकरूपता सारी व्यवहारात । काय पडणार पदरात? । मुळातच न अस्तित्वात । काय येणार प्रत्यक्षात! ॥ ५४ ॥
म्हणे महाराजांप्रत । आम्ही शिंपी मोजण्यात पटाईत । नजर बसते अंदाजात । तेच घडते प्रत्यक्षात ॥ ५५ ॥
जे नाही नाल्यात । कैसे भरणार तुंब्यात ?। उगाच धाडू नका नाल्याप्रत । आणतो दुसरीकडुन क्षणभरात ॥ ५६ ॥
महाराज म्हणाले पितांबरास । मारू नको गप्पांस ।खुणेने सांगती त्यास । तू जावे नाल्यास ॥ ५७ ॥
पितांबर वदे त्यावर । आम्ही मुळचेच चतुर । वारस्यात टाकतो भर । पिढ्या नि पिढ्या खपल्या आजवर ॥५८ ॥
श्री गुरू ! नका धाडू नाल्याला । आणतो मी पाण्याला । दुसरी कडे जाण्याला । आज्ञा करा मला ॥ ५९ ॥
श्री गुरू पाणी नाही नाल्याला । मी न फसणार मृगजळाला । नका धाडू नाल्याला । नका समजू खुळा ॥ ६० ॥
श्री गुरूंनी सर्व ऐकले । स्मित हास्य केले । बालबुद्धीवर हसले । परी नाल्याचेच पाणी मागितले ॥६१ ॥
महाराज म्हणे तू मुळचाच हुशार । सांगे पाणी नसणार । परी मी सांगणार । नाल्यात तुंब्या बुडणार ॥ ६२ ॥
आपण लावू पैज । कोण जिंकतो आज । असे म्हणती महाराज । पितांबर लावतो पैज ॥ ६३ ॥
पितांबर निमूटपणे गेला । नाल्यात तुंब्या बुडवला । तुंब्या क्षणात भरला । तो विस्मयचकित झाला ॥ ६४ ॥
गुरूबोलच खरा ठरला । ह्याचा अनुभव घेतला । पितांबरे तुंब्या आणला । गुरू चरणी माथा ठेवला ॥ ६५ ॥
बंकटास रहस्य कळले । योगी समर्थ ठरले । दोघे त्यांचेच झाले । कृपाशिष मागते झाले ॥ ६६ ॥
श्री गुरू येथे सांगतात । वायफळ बडबडण्यात । काही न प्रत्यक्षात । उपयुक्त आज्ञा पालनात ॥ ६७ ॥
जे न होते बोलण्याने । तेच घडते कृतीने । नराचा नारायण कृतीने । नच नुसत्या बडबडीने ॥ ६८ ॥
जी घागर पाण्याने भरणार । भरलेली आवाज न करणार । जो खरा ज्ञानी असणार । तो कृतीतुनच दावणार ॥ ६९ ॥
ऐसे असावे बोलण्यात । उणे न होई व्यवहारात । शब्द जपावे बोलण्यात । तोल राहतो संसारात ॥ ७० ॥
बंकटास बोलले गुरूवर । नको परतवू भाकरीवर । नच संतुष्ट त्यावर । काढ सुपारी सत्वर ॥ ७१ ॥
बंकटाने सुपारी बरोबर । नाणे ठेवता झाला हातावर । विनये बोलला, “गुरूवर! । करा ह्याचा स्विकार” ॥ ७२ ॥
नाण्यांकडे पाहुन । महाराज बोलले हसून । अरे ब्राह्मण समजून । नको घालवू परतवून ॥ ७३ ॥
नाण्याने न मी तृप्त होणार । मी नाण्याला न भुलणार । नको मज ऐसा व्यवहार । तू मोठा आहेस हुशार ॥ ७४ ॥
नको व्यवहारी नाणे । नको वैभव उसने । सेवा करावी भक्तीभावाने । मी संतुष्ट त्याने ॥ ७५ ॥
मला प्रिय सेवा नामाची । नच तुझ्या धनाची । नको गोष्ट गुरफटण्याची । कधी न सुटका व्हावयाची ॥ ७६ ॥
नामजपात कळते सकळ । संकटे होतात निष्फळ । नामात घालवा इतुका वेळ । जेणे व्यापारात बसेल मेळ ॥ ७७ ॥
नियतीच्या खेळाला । कोण अडवणार त्याला ? । महत्व द्यावे नामाला । तेच उपयुक्त प्रसंगाला ॥ ७८ ॥
महाराज म्हणाले दोघांस । जावे आता कीर्तनास । ऐकावे किर्तनास । आणावे आचरणास ॥ ७९ ॥
गोविंदबुवाच्या कीर्तनास । दोघे जाती ऐकण्यास । आग्रह न केला महाराजांस । जाणले त्यांच्या अधिकारास ॥ ८० ॥
लिंबाच्या झाडापाशी । महाराज बैसले ऐकण्यासी । गोविंदबुवाच्या कीर्तनासी । काय पुसता गर्दीसी ? ॥ ८१ ॥
गोविंदबुवा कीर्तनकार । कुठच्याही निरूपणावर । रसभरीत वर्णन करणार । श्रोत्यांना तल्लीन करणार ॥ ८२ ॥
ईश्वर आपले महत्व पटवतो । वेळोवेळी कीर्तनकारास धाडतो ।सत्वृत्ती, सत्कर्मे करवतो । धर्माचे रक्षण करवतो ॥ ८३ ॥
सारे खेळ भक्तीभावाचे । सारे चाले प्रेरणांचे । तसेच भोळ्या भावाचे । जन जागृतीचे ॥ ८४ ॥
कीर्तनकार ऐसे कीर्तन करतो । श्रोतृवर्ग रंगून जातो । पुन्हा पुन्हा तो जमतो । पुन्हा पुन्हा तो ऐकतो ॥ ८५ ॥
मनावर उमटवतो भक्तीचे ठसे । कीर्तनकारास विसरणार कैसे? । भक्तीचे महात्म्य टिकत असे । नाम महिमा वाढत असे ॥ ८६ ॥
तैसा गोविंदबुवा प्रख्यात । कोरे भक्तीचे लेणे जनमनात । एकादश स्कंधात । हंसगीता पूर्वार्ध वदतात ॥ ८७ ॥
गोविंदबुवा सांगे ओरडून । कीर्तनात मधुन मधुन । म्हणे ईश्वरावाचून । काही न येणार जुळुन ॥ ८८ ॥
ईश्वराने सर्व व्यापले । काही न शिल्लक ठेवले । सर्व त्याचेच म्हणवले गेले । माझे माझे घात आपले ॥ ८९ ॥
भगवंतास आळवा केव्हाही । जेणे भेटे केव्हाही । त्यास आळवा कोठेही । जेणे दिसेल तेथेही ॥ ९० ॥
जो त्याचे अनुसंधानात । भगवंत त्याचे दारात । तो भरला चराचरात । तेणे दिसतो चराचरात ॥ ९१ ॥
ऐसा मनी ध्यास असावा । भगवंत सर्वत्र दिसावा । त्याच्यावर प्रपंच सोपावा । नामात जीव रमवावा ॥ ९२ ॥
नका करू नुसते नवस । नका पाहू सोनेरी कळस । आधी पाया मग कळस । हेच ठसवावे मनास ॥ ९३ ॥
जो ही तत्वे आचरतो । भगवंत त्यासी भेटतो । गोविंदबुवा पूर्वार्धात रंगतो । गजानन उत्तरार्ध वदतो ॥ ९४ ॥
गोविंदबुवा चकित होतो । तो मनी ओळखतो । सारखाच सारख्यास जाणतो । ह्याचा येथे अनुभव होतो ॥ ९५ ॥
जेथे तळमळ ध्यास । तेथे सुयोग सहवास । यास्तव शेगावास । घडला संतसहवास ॥ ९६ ॥
म्हणाले गोविंदबुवा । हा उत्तरार्ध वदणारा । योगी समर्थ दिसतो खरा । आणावे त्यास मंदिरा ॥ ९७ ॥
बंकटलाल नि पितांबर । आणिक मंडळी बरोबर । समर्थांच्या पुढे साचार । सांगे, बोलवे तुम्हास कीर्तनकार ॥ ९८ ॥
केली विनंती सर्वांनी । वाटे यावे समर्थांनी । महाराज न हलले तेथुनी । बसल्या जागेपासुनी ॥ ९९ ॥
गोविंदबुवा अखेर । वदले जोडुन कर । विनविते झाले साचार । म्हणे कृपा करावी एकवार ॥ १०० ॥
प्रार्थना करतात गजाननास । मंदिरात बसण्यास । बसावे उच्च आसनास । कृपा असावी लेकरांस ॥ १०१ ॥
आपणच शंकर खरोखर । आपण बसता बाहेर । मंदिर सुने राहणार । आपणावाचुन न शोभे मंदिर ॥ १०२ ॥
परी गुरू न भुलती आसनाला । बळी न पडती आग्रहाला । मुळचाच मुक्त असलेल्याला । कोण बंदिस्त करणार त्याला? ॥ १०३ ॥
महाराज म्हणाले गोविंदबुवास । ढोंग नको आचरणास । रहावे एकवाक्यतेस । जे जे वदला कीर्तनास ॥ १०४ ॥
आधी स्वयेच अनुसरावे । मग कीर्तन करावे । नुसते पांडित्य नसावे । आधी करावे मग सांगावे ॥ १०५ ॥
तू कीर्तनात शोभतो । परी आमचे पाशी शून्य ठरतो । भागवतातला श्र्लोक सांगतो । परी तैसे न आचरतो ॥ १०६ ॥
तुज जवळ शब्द भांडार । त्याचेवर पोट भरणार । कीर्तनाच्या वार्‍या करणार । हे तुला न शोभणार ॥ १०७ ॥
तुझ्या आग्रहात न तथ्य । जग हे सारे मिथ्य । ईश्वराने सर्व व्याप्त । ईश्वर भरला सर्वात ॥ १०८ ॥
मी न भुलणार आग्रहाला । येथेच ऐकणार कीर्तनाला । नको बळी आग्रहाला । होशील तू फजितीला ॥ १०९ ॥
तू कीर्तन चालू ठेव । नामजप ज्योत तेवत ठेव । नामाची ठेव ऐशी ठेव । तेणे भक्तांस भेटती देव ॥ ११० ॥
नामज्योतीचा प्रकाश पडेल । तिमिर नाश करेल । तेणेच सन्मार्ग मिळेल । तेणेच जग उद्धरेल ॥ १११ ॥
माझे बोलणे ऐक । तू जमवतो कित्येक । परि सोडु नको तत्व एकेक । जे जीवनी उपयुक्त एकेक ॥ ११२ ॥
जावे कीर्तन समाप्त करावे । मला येथेच बसू द्यावे । स्वानुभवाचे बोल वदावे । कोरडे कीर्तन नसावे ॥ ११३ ॥
गोविंदबुवा परत आले । कीर्तन करू लागले । महाराजांत चित्त गुंतलेले । महाराजांविषयीच बोलले ॥ ११४ ॥
हा पांडुरंग साक्षात । जो दिसे पंढरपुरात । तोच आला शेगावात । मंदिरा बाहेर प्रत्यक्षात ॥ ११५ ॥
गोविंदबुवा रंगलेले । कीर्तन तेथेच संपवले । सर्वांना म्हणणे पटले । लोक आपापल्या घरी गेले ॥ ११६ ॥
बंकटलालही घरी गेले । हर्ष चित्तात दाटलेले । पित्यास वृत्तांत वदले । भवानीरामही हर्षभरीत झाले ॥ ११७ ॥
बंकट म्हणे पित्यास । महाराज आणावे घरास । साक्षात पांडुरंग आपल्या घरास । येईल भाग्य उजळण्यास ॥ ११८ ॥
पित्याने होकार दिला । बंकट हर्षभरीत झाला । आनंदे नाचू लागला । भाग्य दिन पाहू लागला ॥ ११९ ॥
असेच चार दिवस गेले । बंकट महाराजांस शोधते झाले । ज्याला त्याला विचारले । महाराज कोठे दिसले ? ॥ १२० ॥
पुढे चार दिवसात । अस्तमानाच्या समयास । दिसली गुरूमाऊली साक्षात । आनंद मावेना गगनात ॥ १२१ ॥
बंकट पुढे गेला । गजानना विनवू लागला । गुरूचरण लाभावे घराला । हीच प्रार्थना तुम्हाला ॥ १२२ ॥
महाराज बघू लागले । बंकटास वाटले । काय आपले चुकले? । महाराजे स्मितहास्य केले ॥ १२३ ॥ 
बंकट मनात समजला । महाराजे होकार दिला । महाराजांच्या कराला । बंकट धरता झाला ॥ १२४ ॥
सूर्य जाता अस्तास । दिवाबत्तीच्या समयास । हा बोधसूर्य उदयास । आला बंकटाच्या घरास ॥ १२५ ॥
घरातले सर्व स्वागतास । नाही पारावार आनंदास । आणले पंचारतीस । ओवाळली महाराजांस ॥ १२६ ॥
पित्याने पहाताक्षणी । हर्षभरीत मनोमनी । साष्टांग वंदन करुनी । महाराजांस केली विनवणी ॥ १२७ ॥
पाटावर बसवले । गुरूपूजन आरंभिले । चरणकमळ धुतले । भाळी गंध लावले ॥ १२८ ॥
गळ्यात घातली पुष्पमाळा । रूळू लागली ती गळा । विलक्षण तेजाला । जो तो पाहू लागला ॥ १२९ ॥
बंकट त्याचा लाडका । कैसा होणार पोरका? । योग असतो एकेकाचा । भाग्योदय बंकटाचा ॥ १३० ॥
समर्थांस मानतो मायबाप । भाव प्रकटतो आपोआप । आनंदाचे न मोजमाप । आनंद होतो अमाप ॥ १३१ ॥
नुसता नाही त्यास बोलवत । आधी ध्यास चित्तात । जेणे गुरू आले घरात । रममाण गुरूसेवेत ॥ १३२ ॥
जरी असतो व्यापारात । परी चित्त गजाननात । रहातो गुरू आज्ञेत । सदा विनम्र भावात ॥ १३३ ॥
व्यापारात जरी पक्का । परी गुरू आज्ञेत नाही फिका । विनय विनम्र भाव ठसा । चेहरा कृतीशी साजेसा ॥ १३४ ॥
भवानीरामे जोडले हात । म्हणे तुम्हीच शंकर साक्षात । आलात प्रदोष समयात । लाडक्या भक्ताच्या घरात ॥ १३५ ॥
भवानीराम मनोमन । विचार करू लागला तत्क्षण । करता ताज्या स्वयंपाकाकारण । योगी जाईल येथून ॥ १३६ ॥
घरातून जाता उपाशी । पातकांच्या राशी । पुण्य राहिले बाजुसी । पातक माझ्या नशीबासी ॥ १३७ ॥
क्षणभर विचार केला । दुपारच्या पुर्‍या दिसू लागल्या । बदाम खारका सोबतीला । देईन त्यांस भोजनाला ॥ १३८ ॥
जे जे आले मनाला । ते ते आणले सेवेला । महाराजांस विनवू लागला । आरंभ करावा भोजनाला ॥ १३९ ॥
गुरू मूर्तीने आरंभ केला । एकेक पदार्थ सेविला । जो जो त्यांस वाढला । भोजने संतुष्ट जाहला ॥ १४० ॥
बंकट मुळचाच भाविक फार । कैसा गुरुस सोडणार? । म्हणे येथेच रहावे रात्रभर । कृपा करावी आमच्यावर ॥ १४१ ॥
गुरूंनी मान्य केले । खुणेनेच पटवले । सारे दर्शनास येऊ लागले । पंढरपुरच ते झाले ॥ १४२ ॥
दुसरा दिवस उजाडला । बंकट त्यांच्या सेवेला । उष्णोदक देता झाला । महाराजांना तोंड धुवायला ॥ १४३ ॥ 
प्रातर्विधी आटोपल्यावर । स्नानास पाणी तयार । जो तो सेवेत तत्पर । सर्व सेवेत सत्वर ॥ १४४ ॥
उष्णोदकाच्या घागरी । महाराजांच्या अंगावरी । घालती त्या नरनारी । होती हर्षभरीत भारी ॥ १४५ ॥
कुणी शिकेकाई लावती । कुणी साबण लावती । कुणी केशर चंदन लावती । सुवासिक जल घालती ॥ १४६ ॥
स्नानविधी संपल्यावर । नेसवला पितांबर । सन्मानाने बसवला गादीवर । योगीराज मुनीवर ॥ १४७ ॥
गुरूंच्या पूजनास । जो तो उत्सुक । बंकटही त्यात एक । होता गुरूंच्या सेवेस ॥ १४८ ॥
बंकट आरंभ करतो । मनोभावे पूजा करतो । अंतर्भाव जागृत होतो । दंभाचरणास वाव नसतो ॥ १४९ ॥
दंभाने मिळत काही नाही । हे खोटे ठरणार नाही । हेच बंकटाच्या ठसले हृदयी । तेणे दंभाचरण नाही ॥ १५० ॥
जे पटले बंकटलालास । तेच पटवावे आपणास । जेणे भाग्य उदयास । जैसे आले बंकटास ॥ १५१ ॥
ज्याने दंभ आचरला । तो भवसागरी बुडाला । जो दंभात रमला । तो कधी न तरला ॥ १५२ ॥
आपण तरण्यास शिकावे । ज्याला वाटे आपण तरावे । त्याने दंभाचरण करू नये । हे तत्व विसरू नये ॥ १५३ ॥
मंगल सुरांचे गुंजन । त्यात होते गुरूपूजन । सर्वत्र आनंदी वातावरण । जणु परमात्म्याचे पूजन ॥ १५४ ॥
गुरूंच्या गळ्यात जानवे । फुलहारांच्या सवे । माणिक मोत्यांनीही सजवावे । जे जे मनात यावे ॥ १५५ ॥
भाळी तिलक केशराचा । अंगी फाया अत्तराचा । सोबत गुलाब जलाचा । सर्वत्र वास सुगंधाचा ॥ १५६ ॥
त्या दिवशी सोमवार । प्रत्यक्ष दारी शंकर । प्रकटला महांकाळेश्वर । की प्रकटला ओंकारेश्वर ॥ १५७ ॥
नैवेद्य नाना तर्‍हेचे । समर्थां अर्पण व्हावयाचे । त्यातच धन्य समजायचे । क्षण भाग्योदयाचे ॥ १५८ ॥
पहाता पहाता दिवस सरला । तिन्ही सांज समय आला । जो तो दर्शनास आला । इच्छाराम बाकी राहिला ॥ १५९ ॥
इच्छाराम नि बंकट । दोघे बंधू चुलत । इच्छाराम शंकर भक्त । मनापासून त्यात रत ॥ १६० ॥
त्याच्या आले मनास । सोमवारच्या दिनास । ऐन प्रदोष समयास । जावे गुरूपूजनास ॥ १६१ ॥
करू व्रत पारणा । दिन अस्तमाना । प्रदोष असताना । घालू गुरूस भोजना ॥ १६२ ॥
होता अस्तमान । प्रदोष वेळ लक्षून । उरकले त्याने स्नान । म्हणे केव्हा धरतो गुरू चरण ॥ १६३ ॥
इकडे गुरू गजानन । मुखी ज्यांच्या गण गण । सभोवताली भक्त गण । करित होते दर्शन ॥ १६४ ॥
तितुक्यात इच्छाराम आला । पूजा साहित्य घेऊन आला । महाराजांसमोर उभा राहिला । पूजा करू लागला ॥ १६५ ॥
आपण जेवल्या वाचून । मी न घेणार अन्न । हीच इच्छा मनोमन । स्विकारावे भोजन ॥ १६६ ॥
सर्व भक्त झाले तृप्त । एकटाच राहिलो अतृप्त । पूर्ण करावा मनोरथ । हाच हेतू मनात ॥ १६७ ॥
आंबेमोहोर भातावर । वरण वाढले त्यावर । तूप ओतले त्यावर । ऐसी मूद पात्रावर ॥ १६८ ॥
जिलबी राघवदास मोतीचूर । पक्वांनांची त्यात भर । सोबत चटणी कोशिंबिर । लाडू अनरसे घीवर ॥ १६९ ॥
बसण्यास नक्षीदार पाट । चौरंगावर ताट । रंगावलीची सोबत । काय विचारता थाट! ॥ १७० ॥
जरी ताट भरलेले । पंचपक्वान्नांनी नटलेले । गजाननास महत्व न उरले । ते मंत्रजपातच रमलेले ॥ १७१ ॥
एवढे तल्लीन जपात । काय लक्ष जेवणात । जे दुसर्‍यास भरवण्यात तृप्त । काय अन्न चवीत होणार लीप्त! ॥ १७२ ॥
केवळ भुकेले आणि व्याकुळ । न जेवणार अन्न सकळ । पूर्ण करण्या भक्त इच्छा सकळ । जेवू लागती सकळ ॥ १७३ ॥
गजानन जेवणार । भोळ्या भावावर । बैसले पानावर । जेवु लागले भरभर ॥ १७४ ॥
अती आग्रह झाला । वाईट परिणाम झाला । भार झाला आतड्याला । तेणे खणाणून उलटीला ॥ १७५ ॥
गुरू म्हणती अरे इच्छाराम ! । अती आग्रह वाईट परिणाम । घाल आग्रहास लगाम । नको आग्रह मुद्दाम ॥ १७६ ॥
इतुके जेवावे । जे पचनेंद्रियाने पचवावे । आतड्यास न भार व्हावे । पिडेस कारण नसावे ॥ १७७ ॥
ऐसे घ्यावे भोजन । जेणे होईल पचन । नको पीडा जाणुन बुजून । आतड्यास भार न होई सहन ॥ १७८ ॥
भोजन वेळी ईश चिंतन । तेणे राहते प्रसन्न मन । स्वस्थ चित्ताने खाता अन्न । बनाल सत्कृत्यास कारण ॥ १७९ ॥
कृती अवलंबे चिंतनावर । ठेवा विश्वास गुरूवर । ऐसे भरावे उदर । जेणे घडेल सदाचरण ॥ १८० ॥
सत्पुरुषांचा आहार सात्विक । तेणे विचार सात्विक । अन्न विचारांचे प्रतिक । विचार अन्नाचे प्रतिक ॥ १८१ ॥
जरी अन्न खावे चवीने । परी सेवावे शांत चित्ताने । नुसत्या मोलमजुरीने । कधी न उदर भरणे ॥ १८२ ॥
जीवास आवडे चित्त शांती फार । जो गुरू आज्ञेत रहाणार । तो ऐशा विचारात रहाणार । तोच चित्त शांती अनुभवणार ॥ १८३ ॥
नको अती आग्रह दुसर्‍याला । तेणे क्लेश त्याला । तृप्त करावे आत्म्याला । नको भाररूप त्याला ॥ १८४ ॥
चित्त प्रसन्न करावे । आत्म्याचे आशिष घ्यावे । अती आग्रह क्लेश नसावे । गुरू बोल ऐकावे ॥ १८५ ॥
समर्थांस आग्रह झाला फार । तेणे उलटी झाली पानावर । जागा साफ केली सत्वर । समर्थां आणले मुळच्या जागेवर ॥ १८६ ॥ 
समर्थ गण गण गणात बोतेत । माऊली रमली नामात । इकडे जो तो उत्सुक दर्शनात । उभा राहू लागला रांगेत ॥ १८७ ॥
रात्रीच्या समयास । दिंड्या आल्या भजनास । रंग चढला भजनास । नामाच्या गजरास ॥ १८८ ॥
नाम येत होते मुखावर । स्त्रिया पुरुष मुग्ध फार । समर्थ टिचक्यांच्या तालावर । नाही आनंदास पारावार ॥ १८९ ॥
मुखी सदा गण गण । जेणे त्यास गजानन । लोकांनी दिले संबोधून । तेच प्रचलित गजानन ॥ १९० ॥
नित्य यात्रा नवी ये‌ई । गजाननाचे दर्शन घेई । समाराधना तेथे होई । जो तो धन्य होई ॥ १९१ ॥
बंकट फार पुण्यवान । जेणे लाभले गजानन । सहज निघे शब्द मुखातुन । सर्व सोहोळा पाहुन ॥ १९२ ॥
कुणी म्हणाले त्यास । अरे! ईश्वरे सोडले पंढरपुरास । आला तुझ्या सदनास । पाहुनी तुझ्या भक्तीभावास ॥ १९३ ॥
भक्त बंकटाचे घर । झाले आता पंढरपुर । गजबजले शेगावनगर । कुणी म्हणे हरिद्वार ॥ १९४ ॥
सूर्याच्या प्रकाशाला । किती वर्णावे त्याला । ऐशा महाराजांच्या लीला । नाना चमत्कृतीला ॥ १९५ ॥
महाराजांचे नित्य नवे । कोठेही बसावे नि रहावे । काहिही खावे नि प्यावे । सर्वच सारखे मानावे ॥ १९६ ॥
कधी करावे मंगल स्नान । कधी करावे गढुळ स्नान । कधी उष्णोदक स्नान । कधी थंडगार स्नान ॥ १९७ ॥
महाराजांच्या लीला अनेक । त्यातल्या काही एक । द्वितियाध्यायी झलक । अजून राहिल्या असंख्य ॥ १९८ ॥
काही वर्णणार तृतियाध्यायात । रहावे अवधानात । महाराजांच्या चमत्कृतीत । रमून जाल त्यात ॥ १९९ ॥
सरस्वती कृपे अध्याय लिहिला । नको गणामहाराज बोलबाला । उणीव असल्याला । नको दोष गजाननाला ॥ २०० ॥
॥ शुभं भवतु ॥
॥ श्री गणपती सरस्वतीर्पणमस्तु ॥
॥ इति श्रीगुरूगजाननलीला ग्रंथस्य द्वितियोऽध्यायः संपूर्णः ॥
</p>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Generic
